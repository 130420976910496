.about-section{
    margin: 0 auto;
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}

.about-section a{
    font-weight: bold;
}

@media only screen and (max-width: 767px){
    .about-section{
        margin-top: 15vh;
    }
}


.about-title{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3.5em;
    color:purple;
    
}

.about-title:before{
    content: '>\a0';
}

@media only screen and (max-width: 767px){
    .about-title{
        font-size: 2.3em;
    }
}

.about-title:span{
    background-color:( purple,0.65);
    animation: caret 1s steps(1) infinite;
}

@keyframes caret{
    50%{
        background-color: transparent;
    }
}


.about-description{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 200;
    font-size: 1.3rem;
    line-height: 40px;
    color:grey;
    margin:40px 0;
    
    
}

@media only screen and (max-width: 767px){
.about-description{
    font-size: 1.2em;
    line-height: 30px;
    max-width: 100%;
    margin:50px 0;

    }
}