.projects.cotainer{
    margin: 0 auto;
}

.project-card{
    position:relative;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 60px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

@media only screen and (max-width: 767px){
    .project-card{
        margin-bottom: 40px;
    }
}
.image-container{

   
  
}
.project-image{

}

@media only screen and (max-width: 1023px){
    .project-image{
        display: none;
    }
}

.project-content{
    padding: 60px;
    width: 50%;
    z-index: 2;
}

@media only screen and (max-width: 1023px){
    .project-content{
        width: 100%;
        padding: 35px;
        box-sizing: border-box;
    }
}

.project-content h3{
    font-weight: 500;
    font-size: 1.8em;
    line-height: 38px;
    color: grey;
    margin-top: 0;
    margin-bottom: 35px;
}

.project-content p {
    margin-bottom: 30px;
    line-height: 27px;
    color: grey;
}

.project-content a{
    font-weight: bold;
}


.project-links{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.project-link{
    margin-right: 20px;
    margin-top: 20px;
    white-space: nowrap;
    display: inline-block;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    letter-spacing: 0.025em;
    text-decoration: none;
    transition: all 0.15s ease;
    font-size: 0.9em;
    font-weight: 600;
    box-sizing: border-box;
    background: purple;
    color: white;
}
.project-link:hover{
    background: blue;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
      0 3px 6px rgba(0, 0, 0, 0.08);
}
.secondary{
    background: #fff;
    color: purple;
}
.secondary:hover{
    background: #fff;
}

.project-skills{
    display: flex;
    flex-wrap: wrap;
}

.project-skill{
    border-radius: 4px;
    border:solid 2px lightgreen;
    padding:8px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size:0.85em;
    color:grey;
}

