body{
  background-color: #f6f9fc;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'pnum';
  font-variant-numeric: proportional-nums;
}

a{
  text-decoration: none;
  color:purple;
  transition: 0.1s ease-in-out;
}
a:hover{
  opacity: 0.8;
}

#topBackground{
  position: absolute;
  top:0;
  width:100%;
  height: 760px;
  transform: skewY(-12deg);
  transform-origin: 0;
  background-color: white;
  z-index:-999;
}

@media only screen and(max-width:767px){
  #topBackground{
    height: 500px;
  }
}

.container-lg{
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 40px;
}

@media only screen and(max-width:767px){
  .container-lg{
    padding: 0 20px;
  }
}

.section-title{
  color:gray;
  font-weight: 500;
  font-size: 1.5em;
  text-align: center;
  padding-top: 50px;
  margin-top: 50px;
  margin-bottom: 80px;
}
@media only screen and(max-width:767px){
  .section-title{
    margin-bottom: 40px;
  }
}

.section-title:after{
  content: '';
  border:2px solid purple;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 50px;
}


